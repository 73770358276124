import { render, staticRenderFns } from "./TermsForm.vue?vue&type=template&id=0f3c44b8&scoped=true&"
import script from "./TermsForm.vue?vue&type=script&lang=js&"
export * from "./TermsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f3c44b8",
  null
  
)

export default component.exports